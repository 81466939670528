import React, { useState } from "react";
import {
  Container,
  Image,
  Navbar,
  Offcanvas,
  Button,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import logo from "./../../assets/images/logo.png";
import { TextRight } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
function NavbarFirst() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigation = useNavigate();

  // /** About Us Module **/
  const handleClickAboutUs = (event) => {
    navigation("/about-us", {
      state: {
        one: "0",
        two:
          (event == "about" && "270") ||
          (event == "values" && "830") ||
          (event == "board" && "1400") ||
          (event == "managment" && "2200") ||
          (event == "global" && "3000"),
      },
    });
  };

  // const handleClickAboutUs = (event) => {
  //   navigation("/about-us", {
  //     state: {
  //       one: "0",
  //       two:
  //         (event == "about" && "400") ||
  //         (event == "board" && "830") ||
  //         (event == "managment" && "1650") ||
  //         (event == "global" && "2400"),
  //     },
  //   });
  // };

  /**  Investors **/
  const handleClickInvestors = (event) => {
    console.log(event);
    navigation("/Investors", {
      state: {
        one: "0",
        two:
          (event == "annual" && "800") ||
          (event == "relations" && "2150") ||
          (event == "faqs" && "0"),
      },
    });
  };

  /**  Product Portfolio **/
  const handleClickProduct = (event) => {
    navigation("/products", {
      state: {
        one: "0",
        two: event == "overview" && "400",
      },
    });
  };

  /** Career **/
  const handleClickCareer = (event) => {
    navigation("/career", {
      state: {
        one: "0",
        two:
          (event == "Careers" && "400") || (event == "joinourteam" && "1000"),
      },
    });
  };

  /** News **/
  const handleClickNews = (event) => {
    navigation("/news", {
      state: {
        one: "0",
        two: (event == "news1" && "0") || (event == "ourevent" && "830"),
      },
    });
  };

  /** Blogs **/
  const handleClickBlogs = (event) => {
    navigation("/Blogs", {
      state: {
        one: "0",
        two: event == "blogs" && "0",
      },
    });
  };

  /** ContactUs **/
  const handleClickContactus = (event) => {
    navigation("/contact-us", {
      state: {
        one: "0",
        two: event == "contactus" && "0",
      },
    });
  };

  // var isActive = context.router.route.location.pathname === this.props.to;
  // var className1 = isActive ? "active" : "";

  return (
    <>
      <Navbar className="bg-light ">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Image src={logo} className="nav-logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="">
              <div class="dropdown">
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/about-us">
                    {" "}
                    <span className="navitemtext"> About Us</span>
                  </Link>
                </Nav.Link>{" "}
                <div class="dropdown-content">
                  <ul>
                    <li
                      className="fs-5"
                      onClick={() => handleClickAboutUs("about")}
                    >
                      <Link
                        to="/about-us"
                        className=" text-decoration-none navitemtext nav-item"
                      >
                        {/* What Is Eugia{" "} */}
                        About Eugia
                      </Link>
                    </li>
                    <li
                      className="fs-5"
                      onClick={() => handleClickAboutUs("values")}
                    >
                      <Link
                        to="/about-us"
                        className=" text-decoration-none navitemtext nav-item"
                      >
                        Our Values
                      </Link>
                    </li>
                    <li
                      className=" fs-5"
                      onClick={() => handleClickAboutUs("board")}
                    >
                      <Link
                        to="/about-us"
                        className=" text-decoration-none navitemtext nav-item"
                      >
                        Directors
                      </Link>
                    </li>
                    <li
                      className=" fs-5"
                      onClick={() => handleClickAboutUs("managment")}
                    >
                      <Link
                        to="/about-us"
                        className=" text-decoration-none navitemtext nav-item"
                      >
                        Managment Team{" "}
                      </Link>
                    </li>
                    <li
                      className=" fs-5"
                      onClick={() => handleClickAboutUs("global")}
                    >
                      <Link
                        to="/about-us"
                        className=" text-decoration-none navitemtext nav-item"
                      >
                        {/* Award */}
                        Global Insignia
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/Investors">
                    {" "}
                    <span className="navitemtext"> Investors</span>
                  </Link>
                </Nav.Link>{" "}
                <div class="dropdown-content">
                  <ul>
                    {/* <li
                      className="fs-5"
                      onClick={() => handleClickInvestors("annual")}
                    >
                      <Link
                        to="/investore"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Annual Report
                      </Link>
                    </li> */}
                    <li
                      className="fs-5"
                      onClick={() => handleClickInvestors("annual")}
                    >
                      <Link
                        to="/Investors"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Our Promoters{" "}
                      </Link>
                    </li>
                    <li
                      className="fs-5"
                      onClick={() => handleClickInvestors("relations")}
                    >
                      <Link
                        to="/Investors"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Investor Relations{" "}
                      </Link>
                    </li>
                    <li
                      className="fs-5"
                    >
                      <Link
                        to="/report"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Reports & Policies
                      </Link>
                    </li>

                    {/* <li
                      className="fs-5"
                      onClick={() => handleClickInvestors("faqs")}
                    >
                      <Link
                        to="/investore"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        FAQ’s
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/products">
                    {" "}
                    <span className="navitemtext">Portfolio</span>
                  </Link>
                </Nav.Link>
                <div class="dropdown-content">
                  <ul>
                    <li
                      className="fs-5"
                      onClick={() => handleClickProduct("overview")}
                    >
                      <Link
                        to="/products"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Overview
                      </Link>
                    </li>
                    <li className="fs-5">
                      <Link
                        to="/treatments"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Key Therapeutic Areas
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/career">
                    {" "}
                    <span className="navitemtext">Careers</span>{" "}
                  </Link>
                </Nav.Link>{" "}
                <div class="dropdown-content">
                  <ul>
                    <li
                      className="fs-5"
                      onClick={() => handleClickCareer("Careers")}
                    >
                      <Link
                        to="/career"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Overview
                      </Link>
                    </li>
                    <li
                      className="fs-5"
                      onClick={() => handleClickCareer("joinourteam")}
                    >
                      <Link
                        to="/career"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Now Hiring
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/news" className=" text-decoration-none">
                    <span className="navitemtext">Media</span>
                  </Link>{" "}
                </Nav.Link>{" "}
                <div class="dropdown-content">
                  <ul>
                    <li className="fs-5">
                      <Link
                        to="/news"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        Press Release
                      </Link>
                    </li>
                    <li className="fs-5">
                      <Link
                        to="/Blogs"
                        className="text-decoration-none navitemtext nav-item"
                      >
                        <span className="navitemtext">Blogs</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="dropdown"
                onClick={() => handleClickContactus("contactus")}
              >
                <Nav.Link className="nav-item my-2 fs-5 mx-4">
                  <Link to="/contact-us" className=" text-decoration-none">
                    <span className="navitemtext">Contact us</span>
                  </Link>
                </Nav.Link>{" "}
              </div>
            </Nav>
            <TextRight
              className="icon-nav text-primary "
              onClick={handleShow}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="text-white bg-off "
      >
        <Offcanvas.Header>
          <div onClick={handleClose} className="cursor ">
            <b> X </b>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="">
            <li className="text-white fs-5 navitem">
              <Link to="/" className="text-white text-decoration-none">
                Home
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/about-us" className="text-white text-decoration-none">
                About Us
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/Investors" className="text-white text-decoration-none">
                Investors
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/products" className="text-white text-decoration-none">
                Portfolio
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link
                to="/treatments"
                className="text-white text-decoration-none"
              >
                Key Therapeutic Areas
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/career" className="text-white text-decoration-none">
                Careers
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/news" className="text-white text-decoration-none">
                Press Release
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link to="/Blogs" className="text-white text-decoration-none">
                Blogs
              </Link>
            </li>
            <li className="text-white fs-5 navitem">
              <Link
                to="/contact-us"
                className="text-white text-decoration-none"
              >
                Contact us
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavbarFirst;
