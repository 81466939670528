import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrow from "./../../assets/images/arrow.png";
import learnimg1 from "./../../assets/images/learn-img-1.png";
import newsimg from "./../../assets/images/news-img.png";

function NewsCard() {
  return (
    <>
      <div className="container ">
        <h5 className="text-uppercase text-secondary my-3"> Related News</h5>
        <div className="row card-container">
          <div className="col-md-4 my-2">
            <div class="card">
              <Image class="card-img-top" src={newsimg} alt="Card image cap" />
              <div class="card-body bg-light">
                <p class="card-text text-light ">24th Oct 2020</p>
                <p class="card-text">
                  Aurobindo Pharma receives USFDA Approval for Guaifenesin
                  Extended-Release Tablets (OTC)
                </p>
                <div className="lernhover cursor mt-5">
                  <span className="text-secondary learn-more  fs-4  mt-5">
                    <Link to="/news-single" className="text-decoration-none">
                      Learn More
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="card">
              <Image class="card-img-top" src={newsimg} alt="Card image cap" />
              <div class="card-body bg-light">
                <p class="card-text text-light ">24th Oct 2020</p>
                <p class="card-text">
                  Aurobindo Pharma receives USFDA Approval for Guaifenesin
                  Extended-Release Tablets (OTC)
                </p>
                <div className="lernhover cursor mt-5">
                  <span className="text-secondary learn-more  fs-4  mt-5">
                    <Link to="/news-single" className="text-decoration-none">
                      Learn More
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="card">
              <Image class="card-img-top" src={newsimg} alt="Card image cap" />
              <div class="card-body bg-light">
                <p class="card-text text-light ">24th Oct 2020</p>
                <p class="card-text">
                  Aurobindo Pharma receives USFDA Approval for Guaifenesin
                  Extended-Release Tablets (OTC)
                </p>
                <div className="lernhover cursor mt-5">
                  <span className="text-secondary learn-more  fs-4  mt-5">
                    <Link to="/news-single" className="text-decoration-none">
                      Learn More
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-12 text-center my-3">
            <h5 className="view-more"> View More</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsCard;
