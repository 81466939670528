import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";

import doctors from "./../assets/images/singlenews.png";
import arrow from "./../assets/images/arrow.png";
import director1 from "./../assets/images/director1.png";
import director2 from "./../assets/images/director2.png";
import phase1 from "../assets/images/Kamat.jpg";
import phase2 from "../assets/images/Kannan.jpg";
import phase3 from "../assets/images/Santhanam.jpg";
import phase4 from "../assets/images/Kosaraju.jpg";
import phase5 from "../assets/images/phase5.png";
import phase6 from "../assets/images/phase6.png";
import award1 from "../assets/images/award1.png";
import award2 from "../assets/images/award2.png";
import award3 from "../assets/images/award3.png";
import abouthero from "../assets/images/about-hero.png";
import { Card, Container, Image } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import FooterSecond from "../components/footer/FooterSecond";
import PageHistory from "../components/navs/PageHistory";
import GoToTopButton from "../components/footer/GoToTopButton";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { useLocation } from "react-router-dom";

function BlogDetails(props) {
  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [imgBlog, setImgBlog] = useState();
  const [description, setDescription] = useState();
  const { state } = useLocation();

  useEffect(() => {
    setData(state);
    setTitle(state?.ele?.title);
    setImgBlog(state?.ele?.image);
    setDescription(state?.ele?.description);
  });

  return (
    <>
      <NavbarFirst />

      <div className="herodiv">
        <img src={abouthero} alt="hero Img" width="100%" />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2">Blogs Details</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Blogs" />
      {/* 
      <div className="container" data-aos="fade-up" data-aos-duration="3000">
        <div className="row my-3 mt-5">
          <h2 className=" my-3">
            <b>{title} </b>
          </h2>
        </div>
      </div> */}
      <div className="container" data-aos="fade-up" data-aos-duration="3000">
        {/* <div className="row my-3 mt-5">
          <div className="col-md-12">
            <img
              src={imgBlog}
              alt=" Img "
              width="100%"
              height="400px"
              className="img-fluid"
            />
          </div>
        </div> */}
        <div className="row my-3 mt-5">
          <div className=" my-3 mt-5">
            <h2 className=" my-3">
              <b>{title} </b>
            </h2>
          </div>
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <p className="mt-5 font1">
            <span>
              {" "}
              <b>Disclaimer : </b>
            </span>{" "}
            The information, including but not limited to text, graphics,
            images, and other material published through Eugia's
            channels/website, are for informational purposes only. No material
            on this website/social media platforms is intended as a substitute
            for professional medical advice, medical recommendations, diagnosis
            or treatments. Always seek the advice of your doctor, physician or a
            qualified health provider with any questions regarding a medical
            condition or treatment or before undertaking a new health care
            regime.
          </p>
        </div>
      </div>

      <FooterFirst data-aos="fade-up" data-aos-duration="3000" />
      <GoToTopButton />
    </>
  );
}

export default BlogDetails;
