import React from "react";
import { Image } from "react-bootstrap";
import heroimage from "./../../assets/images/hero-news.png";
function NewsHero() {
  return (
    <div className="heroimage-section">
      <Image src={heroimage} className="w-100 img-fluid hero-img" alt="img" />
      <h2 className="text-white hero-text"> News and Media</h2>
    </div>
  );
}

export default NewsHero;
