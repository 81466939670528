import React from 'react'

function HeroSection({props}) {
  return (
    <div>
      <div>
        <img
          src={props?.logo}
          alt="hero Img"
          width="100%"

        />


        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> {props?.text}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
