import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";

import doctors from "./../assets/images/singlenews.png";
import arrow from "./../assets/images/arrow.png";
import director1 from "./../assets/images/director1.png";
import director2 from "./../assets/images/director2.png";
import phase1 from "../assets/images/Kamat.jpg";
import phase2 from "../assets/images/Kannan.jpg";
import phase3 from "../assets/images/Santhanam.jpg";
import phase4 from "../assets/images/Kosaraju.jpg";
import phase5 from "../assets/images/phase5.png";
import phase6 from "../assets/images/phase6.png";
import award1 from "../assets/images/award1.png";
import award2 from "../assets/images/award2.png";
import award3 from "../assets/images/award3.png";
import abouthero from "../assets/images/Blogs-hero.png";
import { Card, Container, Image } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import FooterSecond from "../components/footer/FooterSecond";
import PageHistory from "../components/navs/PageHistory";
import GoToTopButton from "../components/footer/GoToTopButton";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import line1 from "../../src/assets/images/line-2.png";

function Blogs() {
  /** Particulat postion **/
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, dataSet] = useState([]);
  const des = data.slice(0, 10);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/blogs");

        if (!response.error) {
          dataSet(response);
        } else {
          // console.log(" Blog API Not Working");
          // toast.error("Blog API Not Working");
        }
      } catch (err) {
        // console.error(err.message);
        // toast.error(err.message);
      }
    }

    fetchMyAPI();
  }, []);

  const navigate = useNavigate();

  const handleSendData = (ele) => {
    navigate("/BlogDetails", {
      state: {
        ele,
      },
    });
  };

  return (
    <>
      <NavbarFirst />

      <div className="herodiv">
        <img
          src={abouthero}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2">Blogs</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Blogs" />

      <div className="container" data-aos="fade-up" data-aos-duration="3000">
        {/* <div className="row my-3 mt-5">
          <h2 className=" my-3">
            <b>All Blogs </b>
          </h2>
          <div className="row">
            {data.map((ele) => (
              <>
                <div className="col-md-6 my-3">
                  <Card className="border-0 bg-light">
                    <Card.Body>
                      <div className="row">
                        <div className="col-md-6 ">
                          {" "}
                          <img
                            variant="top"
                            className="card-blog"
                            src={ele?.image}
                          />
                        </div>
                        <div className="col-md-6">
                          <Card.Title className="font2 fs-2">
                            {ele?.title}
                          </Card.Title>
                          <Card.Text
                            className="text-blog "
                            dangerouslySetInnerHTML={{
                              __html: ele?.description.slice(0, 100),
                            }}
                          ></Card.Text>

                          <div className="">
                            <p
                              className="learn-more text-secondary mt-3"
                              onClick={() => handleSendData(ele)}
                            >
                              <Link to="">EXPLORE BLOGS</Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </>
            ))}
          </div>
        </div> */}
        <div className="my-2">
          <div className=" ">
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-12 py-5">
                  {/* <div className="text-center">
                    <img src={line1} className="img-fluid" width={80} />
                  </div> */}
                  <h2 className="text-center text-primary  fs-2 font2">
                    Blogs & Articles
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="row">
                  {data ? (
                    [...data].reverse().map((ele) => (
                      <>
                        <div className="col-md-6 ">
                          <div className="border-0 bg-light">
                            <>
                              <div className="row my-2">
                                {/* <div className="col-md-6 ">
                                  {" "}
                                  <img
                                    variant="top"
                                    className=" img-fluid"
                                    src={ele?.image}
                                  />
                                </div> */}
                                <div className="col-md-12 ">
                                  <div className="blogbox px-4 py-3">
                                    <h5 className="font2 fs-2">{ele?.title}</h5>
                                    <p
                                      className="text-blog font1 text-light "
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.description.slice(0, 48),
                                      }}
                                    ></p>

                                    <p
                                      className="learn-more text-secondary mt-3"
                                      onClick={() => handleSendData(ele)}
                                    >
                                      <Link>EXPLORE BLOGS</Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <h1>hello</h1>
                  )}
                </div>
                <p className="mt-5 font1">
                  <span>
                    {" "}
                    <b>Disclaimer : </b>
                  </span>{" "}
                  The information, including but not limited to text, graphics,
                  images, and other material published through Eugia's
                  channels/website, are for informational purposes only. No
                  material on this website/social media platforms is intended as
                  a substitute for professional medical advice, medical
                  recommendations, diagnosis or treatments. Always seek the
                  advice of your doctor, physician or a qualified health
                  provider with any questions regarding a medical condition or
                  treatment or before undertaking a new health care regime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterFirst data-aos="fade-up" data-aos-duration="3000" />
      <GoToTopButton />
    </>
  );
}

export default Blogs;
