import React from "react";
import { Image } from "react-bootstrap";
import img1 from "./../../assets/images/singlenews.png";
function NewsSingleImg() {
  return (
    <div className="container my-3">
      <div className="row">
        <div className="col-md-12">
          <h5 className="text-secondary my-3"> Press Release</h5>
          <Image src={img1} className="w-100 img-fluid" alt="img" />
        </div>
      </div>
    </div>
  );
}

export default NewsSingleImg;
