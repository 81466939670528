import React from "react";
import event from "./../../assets/images/event.png";
function NewsEvent() {
  return (
    <div className="event-div text-center ">
      <h3 className="text-white my-3 py-3"> Our Event</h3>
      <img src={event} className="text-center img-event" />
      <div className="event-text-div">
        <h3 className="event-text my-3 font2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor.
        </h3>
      </div>
    </div>
  );
}

export default NewsEvent;
