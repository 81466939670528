import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "./../../assets/images/logo.png";
import { Facebook, Instagram, Twitter, Linkedin } from "react-bootstrap-icons";
import "./../../css/Responsiveness.css";
function FooterFirst() {
  const navigation = useLocation();

  const clickHome = (event) => {
    navigation("/", {
      state: {
        one: "0",
        two: event == "home" && "0",
      },
    });
  };
  return (
    <footer>
      <div className="container " style={{ marginBottom: "2%" }}>
        <div className="row footer-row">
          <div className="col-md-4">
            <Link to="/" className=" text-secondary text-decoration-none">
              <Image src={logo} width="210px" className="" />
            </Link>

            {/* <p className="w-50">
              We are a global pharmaceutical company with the belief that
              everyone, everywhere deserves access to affordable quality
              medicines.
            </p> */}
            <h5 className="mt-3 font2" style={{ marginLeft: "2%" }}>
              <b>Follow Us</b>
            </h5>
            <div
              className="col-md-6 social-media-icons"
              style={{ paddingTop: "2%", marginRight: "25%" }}
            >
              <ul className="social-icon">
                <a
                  href="https://www.linkedin.com/company/eugia-pharma-specialties-pvt-ltd/"
                  target="_blank"
                >
                  <li className="mx-2 fs-2 text-primary">
                    <Linkedin />
                  </li>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100085172124730"
                  target="_blank"
                >
                  <li className="mx-2 fs-2 text-primary">
                    <Facebook />
                  </li>
                </a>
                <a
                  href="https://www.instagram.com/eugiapharma/"
                  target="_blank"
                >
                  <li className="mx-2 fs-2 text-primary">
                    <Instagram />
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <div className="col-md-4" style={{ marginTop: "2%" }}>
            <h3 className="text-primary font2">Quick links</h3>
            <ul>
              <li onClick={() => clickHome("home")}>
                <Link to="/" className="  text-decoration-none text-light">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="  text-decoration-none text-light"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/Investors"
                  className="  text-decoration-none text-light"
                >
                  Investors
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/treatments"
                  className="  text-decoration-none text-light"
                >
                  Treatments
                </Link>
              </li> */}
              <li>
                <Link
                  to="/products"
                  className="  text-decoration-none text-light"
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="/PrivacyPolicy"
                  className="  text-decoration-none text-light"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/report"
                  className="  text-decoration-none text-light"
                >
                  Reports & Policies
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/Responsibility"
                  className="  text-decoration-none text-light"
                >
                  Responsibility
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/career"
                  className="  text-decoration-none text-light"
                >
                  Career
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="col-md-4" style={{ marginTop: "2%" }}>
            <h3 className="text-primary font2">Get in touch</h3>
            <ul>
              <li>
                <Link
                  to="/contact-us"
                  className="  text-decoration-none text-light"
                >
                  Contact Us
                </Link>
              </li>{" "}
              {/* <li>
                <Link
                  to="/career"
                  className="  text-decoration-none text-light"
                >
                  Career
                </Link>
              </li>{" "} */}
              <li>
                <Link
                  to="/Investors"
                  className="  text-decoration-none text-light"
                >
                  Investors
                </Link>
              </li>
              <li>
                <Link
                  to="/career"
                  className="  text-decoration-none text-light"
                >
                  Career
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row copy-right ">
          <div className="col-md-6">
            <p
              className="text-primary"
              style={{ marginLeft: "1%", marginTop: "6%" }}
            >
              © copyright to eugia &nbsp;
              {/* <b>
                <Link to="/" className="text-decoration-none">
                  Moshi Moshi
                </Link>
              </b> */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterFirst;
