import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import FooterSecond from "../components/footer/FooterSecond";
import GoToTopButton from "../components/footer/GoToTopButton";
import NavbarFirst from "../components/navs/Navbar";
import PageHistory from "../components/navs/PageHistory";
import ApiService from "../service/ApiService";
import treatmentshero from "./../assets/images/treatments-hero.png";
import img1 from "./../assets/images/tretment-img-1.png";

function TreatmentsPage() {
  return (
    <div>
      <NavbarFirst />
      <div className="herodiv">
        <img
          src={treatmentshero}
          alt="hero Img "
          width="100%"
          className="img-fluid"
        />
      </div>
      <PageHistory Text="Treatments" />
      <div className="container">
        <div className="row my-3">
          <div className="col-md-12 text-center text-primary">
            <h2>Recovery and rejuvenation, one</h2>
            <h2>
              <b> treatment at a time </b>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 my-3">
            <img
              src={img1}
              alt="hero Img "
              width="100%"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6">
            <h2 className="text-primary my-3">
              World-class service for those in need
            </h2>
          </div>
          <div className="col-md-6">
            <p>
              In addition to our patent-protected products, we also develop and
              offer multiple treatments and therapies. With deliberate and
              constant efforts in R&D, we aim to stay a step ahead of any
              ailment that might cause any trouble to anyone.
            </p>
          </div>
        </div>
      </div>
      <FooterSecond />

      <FooterFirst />
      <GoToTopButton />
    </div>
  );
}

export default TreatmentsPage;
