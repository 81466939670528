import React from "react";
import { Image } from "react-bootstrap";
import heroimage from "../../assets/images/hero-investore.png";
function InvestoreHero() {
  return (
    <div className="heroimage-section">
      <Image src={heroimage} className="w-100 img-fluid hero-img" alt="img" />
      <h2 className="text-white hero-text">Investors</h2>
    </div>
  );
}

export default InvestoreHero;
