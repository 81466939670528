import React, { useEffect, useState, useRef } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import imgfull from "../assets/images/home-img-full.png";
import icon1 from "./../assets/images/h-icon-1.png";
import icon2 from "./../assets/images/h-icon-2.png";
import icon3 from "./../assets/images/h-icon-3.png";
import homeproduct from "./../assets/images/home-product.png";
import Slide1 from "./../assets/images/Slide1.png";
import Slide2 from "./../assets/images/Slide2.png";
import Slide3 from "./../assets/images/Slide3.png";
import homeservice from "./../assets/images/home-service-1.png";
import blog1 from "./../assets/images/blog1.png";
import blog2 from "./../assets/images/blog2.png";
import blog3 from "./../assets/images/blog3.png";
import blog4 from "./../assets/images/blog4.png";
import virus from "./../assets/images/virus.png";
import image1 from "./../assets/images/image1.png";
import glow from "./../assets/images/glow.png";
import forward from "./../assets/images/forward1.png";
import youtube from "./../assets/images/youtube.png";
import operation from "./../assets/images/operartion.jpeg";
import communityimg from "./../assets/images/community.png";
import Second from "./../assets/images/second.png";
// import Third from "./../assets/images/Third.jpg";
import Third from "./../assets/images/Pharma.png";

import Video from "../assets/video.mp4"
import bg_blue from "./../assets/images/bg_blue.png";
// import '../css/Responsiveness.css'

import {
  Col,
  Container,
  Image,
  Row,
  Carousel,
  Modal,
  Button,
  Card,
} from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import FooterSecond from "../components/footer/FooterSecond";
import { ArrowRight } from "react-bootstrap-icons";
import GoToTopButton from "../components/footer/GoToTopButton";
import img from "../../src/assets/images/img.png";
import line from "../../src/assets/images/line-1.png";
import loaderimg from "../../src/assets/images/loader.png";
import line1 from "../../src/assets/images/line-2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Helmet } from "react-helmet";

function HomePage() {
  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, dataSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/blogs");

        if (!response.error) {
          let dd = response.slice(0, 4);
          dataSet(dd);
        } else {
          // console.log(" Blog API Not Working");
          // toast.error("Blog API Not Working");
        }
      } catch (err) {
        // console.error(err.message);
        // toast.error(err.message);
      }
    }

    fetchMyAPI();
  }, []);

  console.log(data);
  const navigate = useNavigate();

  const handleSendData = (ele) => {
    navigate("/BlogDetails", {
      state: {
        ele,
      },
    });
  };

  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);


  return (
    <>
      <div>
        <Helmet>
          <title>Eugia Pharma</title>
          <meta
            name="description"
            content="Eugia is a Pharma Company in India, providing affordable & accessible specialized products like General Injectables, Oncology, Ophthalamics & Hormonal products."
          />
        </Helmet>
        <NavbarFirst />

        <div
          className="first_section pt-5 displaynone"
          style={{ background: "#F1F1F1" }}
        >
          <div className="menu mt-5">
            <Container>
              <Row>
                <Col className="cont col-md-6">
                  <div className="left_section">
                    <p className="support font2">
                      {/* Supporting */}
                      Leveraging
                    </p>
                    <p className="human_tag">
                      {/* Human */}
                      Science
                    </p>
                    <p className="health_tag font2">
                      {/* health */}
                      for Humankind
                    </p>

                    <p className="para1 font1">
                      {/* We believe that */}
                      Paving way towards
                      <br />
                      {/* everyone everywhere */}
                      affordable and accessible
                      <br />
                      {/* deserves affordable and */}
                      healthcare for all.
                      <br />
                      {/* accessible medical care */}
                    </p>
                    <div className="virus_image">
                      <img src={virus} alt="" />
                    </div>
                  </div>
                </Col>
                <Col className="col-md-6">
                  <div className="right_section">
                    <Carousel className="carousel">
                      <Carousel.Item>
                        <img
                          className="d-block w-100 carousal_image"
                          // src={glow}
                          src={Second}
                          alt="Second slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100 carousal_image"
                          // src={operation}
                          // src={Third}
                          src={Third}
                          alt="Third slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100 carousal_image"
                          src={image1}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* -=================== */}

        <div className="container divslider">
          <div className="row">
            <div className="col-4 text-end">
              <div className="Leveragingtextdiv">
                <p className="Leveraging p-0 m-0 fs-3">Leveraging</p>
                <p className="Science p-0 m-0">Science</p>
                <p className="Humankind p-0 m-0 fs-3">for Humankind</p>
              </div>
              <div>
                <p className="ptaghome">
                  Paving way towards affordable and accessible healthcare for
                  all.
                </p>
              </div>
            </div>

            <div className="col-8">
              {" "}
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide1}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide2}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide3}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
        <div className=" container ">
          <div className="" style={{marginTop:'50px',padding:'0 12px' }}>
            <div className="text-center m-auto">
              <span className="text-primary  font2 fs-1 ">
                Envisioning a State of
                <b className="mx-2">Global Well-Being.</b>
              </span>
              <p className="font1 text-light section-1-p my-4">
                Our aim is to deliver good-health globally, expeditiously. Our
                therapies extend to unmet medical needs in specialized products
                such as General Injectables, Oncology, Ophthalamics and Hormonal
                products.
              </p>
            </div>
            <div className="cursor">
              <div>
                <video controls autoPlay muted loop
                  src={Video}
                  width="100%"
                  />
                   {/* <img src={bg_blue} className="video_bg" /> */}
              </div>
              
              {/* <iframe
                width="100%"
                height="500" 
                src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
              ></iframe>{" "}  */}
              {/* <img src={youtube} className="h-100 w-100 img-fluid" /> */}
            </div>
          </div>
        </div>

        <div className="container my-5 py-5 diviconhome">
          <div className="row text-center  my-2">
            <div className="col-md-4 p-3 ">
              <img src={icon1} alt="" width="35%" className="img-fluid" />
              <h5 className="fs-1 my-4 font2">120+ Countries</h5>
              <p className="text-light mx-5 font1">
                We are proud to be operative in over 120+ countries, leveraging
                our expertise and valuable services across the globe.
              </p>{" "}
            </div>
            <div className="col-md-4 p-3">
              <img src={icon2} alt="" width="35%" className="img-fluid" />
              <h5 className="fs-1 my-4 font2">4,000+ Employees</h5>
              <p className="mx-5 text-light font1">
                Our global team works relentlessly to discover path breaking
                innovations which make a difference.{" "}
              </p>
            </div>
            <div className="col-md-4 p-3">
              <img src={icon3} alt="" width="35%" className="img-fluid" />
              <h5 className="fs-1 my-4 font2">250+ Global Approvals</h5>
              <p className="mx-5 text-light font1">
                We have over 250 globally approved generics that have furthered
                our vision of making human lives better.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row ">
            <div className="col-md-6 p-4 text-left">
              <h5 className="fs-1 my-1 font2">Our Portfolio</h5>

              <div className=" ">
                <img src={line1} className="img-fluid" width={80} />
              </div>
              <p className="text-primary fs-1 font2 portfoliotext">
                We cater to a wide range of medical therapies - from
                anti-infective to oncology treatments.
              </p>
              <p className="text-dark fs-5 font1 ">
                Our responsibility extends to not just patients, but to the
                communities around us and to our stakeholders.{" "}
              </p>

              <div className="lernhover cursor mt-5">
                <span className="text-secondary learn-more  fs-5 ">
                  <Link to="/products" className="text-decoration-none ">
                    EXPLORE
                  </Link>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <img src={homeproduct} alt="" className="w-100 img-fluid" />
            </div>
          </div>
        </div>

        {/* <div className="my-5 py-5 divblogsmobile">
          <div className="my-2 divblogsmobile1">
            <div className="bg-light ">
              <div className="container mt-5">
                <div className="row">
                  <div className="col-md-12 py-5 Staydiv">
                    <h5 className="fs-1  font2 text-center my-1">
                      Stay Updated With Us
                    </h5>

                    <div className="text-center">
                      <img src={line1} className="img-fluid" width={80} />
                    </div>
                    <h2 className="text-center text-primary  fs-2 font2">
                      Blogs & Articles
                    </h2>
                  </div>
                </div>
                <div className="row">
                  {data ? (
                    data.map((ele) => (
                      <>
                        <div className="col-md-6 ">
                          <div className="border-0 bg-light">
                            <>
                              <div className="row my-2">
                                <div className="col-md-6 ">
                                  {" "}
                                  <img
                                    variant="top"
                                    className=" img-fluid"
                                    src={ele?.image}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <div className="blogbox">
                                    <h5 className="font2 fs-2">
                                      {ele?.title.slice(0, 20)}...
                                    </h5>
                                    <p
                                      className="text-blog font1 text-light "
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.description.slice(0, 70),
                                      }}
                                    ></p>

                                    <p
                                      className="learn-more text-secondary mt-3"
                                      onClick={() => handleSendData(ele)}
                                    >
                                      <Link>EXPLORE BLOGS</Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <h1>hello</h1>
                  )}
                </div>
                <div className="row text-center py-5 viewmore">
                  <div className="col-md-12">
                    <div className="lernhover cursor ">
                      <span className="text-secondary learn-more  fs-5 ">
                        <Link to="/Blogs" className="text-decoration-none ">
                          VIEW MORE
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* sisplaynone */}
        <div className=" container displaynone ">
          <div className="row " data-aos="fade-up" data-aos-duration="3000">
            <div className="col-md-4 mt-5 pt-5">
              <div className="mt-5 pt-0">
                <p className="forward-Moving font2 mb-0">
                  {/* Supporting */}
                  Moving
                </p>
                <p className="forward-Forward font2">
                  {/* Human */}
                  Forward
                </p>
                <p className="forward-Together font2">
                  {/* health */}
                  Together
                </p>
              </div>
            </div>
            <div className="col-md-8 forward">
              <div className=" forward-text">
                <p className="text-white fs-5 font1 ">
                  <br />
                  <br />
                  <br />
                  We at eugia believe that medical treatment should be
                  patient-centric and value-oriented. To achieve this we have
                  established 3 fundamental pillars at Eugia: superior patient
                  experience, quality and value-based care.{" "}
                </p>
                <div className="lernhover cursor my-5">
                  <span className="text-secondary learn-more  fs-5 ">
                    <Link
                      to="/career"
                      className="text-decoration-none text-white"
                    >
                      LEARN MORE
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5 py-5 FooterSecondmobile">
          <div className="my-2">
            <FooterSecond />
          </div>
        </div>
        <FooterFirst />
        <GoToTopButton />
      </div>
    </>
  );
}

export default HomePage;
