import React,{useState,useEffect} from 'react'
import { Image,Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import { ToastContainer,toast, Zoom } from "react-toastify";
import arrow_left from "../assets/images/arrow_left.png";
import upload from "../assets/images/upload.png";
import ApiService from "../service/ApiService";

function General() {
    const [loadstate, setloadstate] = useState(false);
    const [formFields, setFormFields] = useState({
        fullName: "",
        email: "",
        number: "",
        message: "",
      });
    
      const [formError, setFormError] = useState({
        fullName: "",
        email: "",
        number: "",
        message: "",
      });
    const handleOnChange = (e) => {
        const p = { ...formFields };
        p[e.target.name] = e.target.value;
        setFormFields(p);
    
        setFormError({
          ...formError,
          [e.target.name]: null,
        });
      };
      const handleValidation = () => {
        // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regText = /[A-Za-z]/;
        const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const newErros = {};
        const { fullName, email, number, message } = formFields;
        if (!fullName) {
          newErros.fullName = "Please Enter Full Name";
        } else if (fullName && !regText.test(fullName)) {
          newErros.fullName = "Full Name should be text";
        } else if (fullName && fullName.length > 50) {
          newErros.fullName = "FullName should be below 50 charecters";
        }
    
        if (!email) {
          newErros.email = "Please Enter Email";
        } else if (email && !rangEmail.test(email)) {
          newErros.email = "Please Enter Corect Email";
        }
    
        if (!number) {
          newErros.number = "please enter mobile number";
        } else if (number && number.length != 10) {
          newErros.number = "Mobile Number should be 10 digits";
        }
    
        if (!message) {
          newErros.message = "Please enter Message";
        }
    
        return newErros;
      };
      const handelSubmit = async (e) => {
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
          setFormError(handleValidationObject);
        } else {
          try {
            setloadstate(true);
    
            const payload = {
              name: formFields.fullName,
              email: formFields.email,
              mobile_no: formFields.number,
              description: formFields.message,
              category: "contactus",
            };
    
            const addContact = await ApiService.postData("/create_user", payload);
            setloadstate(false);
    
            if (!addContact.err) {
              const arr = { ...formFields };
              arr.fullName = "";
              arr.email = "";
              arr.number = "";
              arr.message = "";
              setFormFields(arr);
              toast.success("Success");
            } else {
              toast.error(addContact.err, "Please Fill Up All Fields");
            }
          } catch (err) {
            toast.error(err.message, "Please Fill Up All Fields");
          }
        }
      };
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
      <Helmet>
        <title>Eugia Pharma - general</title>
        <meta name="description" content="Eugia- General" />
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <NavbarFirst />

      <div className="container general-head">
        <div className="general-head2">
          <Link to="/contact-us">
            <Image
              src={arrow_left}
              style={{ width: "40px", height: "40px", cursor: "pointer" }}
            />
          </Link>
          <h1 className="text-primary  font2 display-5">General Enquiry</h1>
        </div>
        <div className="contact-bg py-3">
          <div className="row">
            <div className="col-md-6 event-list">
                <input
                  type="text"
                  className="form-control contact-input mt-5"
                  placeholder="Full Name"
                  onChange={handleOnChange}
                  name="fullName"
                  value={formFields.fullName}
                />
                <span className="text-danger">{formError.fullName}</span>
                <input
                  type="text"
                  className="form-control contact-input  mt-5"
                  placeholder="Email address"
                  onChange={handleOnChange}
                  name="email"
                  value={formFields.email}
                />
                <span className="text-danger">{formError.email}</span>
            </div>
            <div className="col-md-6 event-list">
              <input
                type="number"
                className="form-control contact-input  mt-5"
                placeholder="Phone Number"
                onChange={handleOnChange}
                name="number"
                value={formFields.number}
                id="numbers-input"
                maxLength={10}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
              />
              <span className="text-danger">{formError.number}</span>
              <div className="">
                <input
                  type="text"
                  className="form-control contact-input  mt-5"
                  placeholder="Type your message here"
                  onChange={handleOnChange}
                  name="message"
                  value={formFields.message}
                />
                <span className="text-danger">{formError.message}</span>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn bg-secondary text-white py-2 rounded-0 mt-5 text-center"
                onClick={handelSubmit}
              >
                Submit
                {loadstate ? (
                  <Spinner animation="border" variant="Primary" size="sm" />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <div className="my-2">
          <FooterFirst />
        </div>
      </div>
    </div>
  );
}

export default General