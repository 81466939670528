import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";

import Aos from "aos";
import "aos/dist/aos.css";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import abouthero from "../assets/images/about-hero.png";
import FooterSecond from "../components/footer/FooterSecond";
import GoToTopButton from "../components/footer/GoToTopButton";
import PageHistory from "../components/navs/PageHistory";
import "../css/aboutus.css";
import "../css/scaletenview.css";
import ApiService from "../service/ApiService";
import doctors from "./../assets/images/about-doctors.png";
import mapimg from "./../assets/images/mapimg.png";
import redmaplocation from "./../assets/images/redmap.png";
import greenlocation from "./../assets/images/greenlocation.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroSection from "../components/HeroSection";
import icon1 from "./../assets/images/Equality.png";
import icon2 from "./../assets/images/Unity.png";
import icon3 from "./../assets/images/Growth.png";
import icon4 from "./../assets/images/Integrity.png";
import icon5 from "./../assets/images/Accountability.png";
import { Helmet } from "react-helmet";
function AboutPage() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /** Particulat postion **/
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, dataSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/management");

        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);
  const [dataBoard, dataBoardSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/director");

        if (!response.error) {
          dataBoardSet(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);

  const [awardsList, setAwardsList] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/awards");

        if (!response.error) {
          setAwardsList(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - About Us</title>
        <meta
          name="description"
          content="Eugia Pharmaceuticals is a patient centric manufacturing company, specialized products such as General Injectables, Oncology, Ophthalamics and Hormonal products."
        />
      </Helmet>
      <NavbarFirst />
      <div className="herodiv">
        <img src={abouthero} alt="hero Img" width="100%" />
        <div className="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> About Us</h2>
          </div>
        </div>
      </div>

      <PageHistory Text="About Us" />
      <div className="container " data-aos="fade-up" data-aos-duration="3000">
        <div className="row  my-4 py-5 ">
          <div className="col-md-6 px-5 divaboutpage">
            <img src={doctors} alt="" className="w-100 h-100 " />
          </div>
          <div className="col-md-6  px-5 divaboutpage">
            <h5 className="Eugiaabout  font2 fs-3">
              <b> Eugia</b>
            </h5>

            <p className="text-primary fs-1   font2">
              An organisation committed to <br /> <b> Quality and Safety</b>
            </p>
            <p className=" fs-5 font1 text-justify about-text-p">
              We at eugia believe that medical treatment should be
              patient-centric and value-oriented. To achieve this we have
              established 3 fundamental pillars at Eugia: superior patient
              experience, quality and value-based care.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-5 py-3 valuefordesktops" id="#values">
        <div className="row">
          <h1 className="text-center text-primary  font2 display-5">
            Our Values
          </h1>
          <p className="text-center text-light  font1  ">
            We operate from a set of ineradicable core values that
            <br /> reflects every aspect of Eugia
          </p>
        </div>{" "}
        <div className="values-row text-center  my-2 mt-3">
          <div className=" p-3 valuesbox">
            <div className="imgdivvalues">
              <img src={icon1} alt="" width="40%" className="img-fluid" />
            </div>
            <h5 className="fs-1 my-4 font2 text-primary">
              <b> E</b>quality
            </h5>
            <p className="text-light  font1">
              We value everyone and treat people with respect, equality and
              dignity.{" "}
            </p>{" "}
          </div>
          <div className=" p-3 valuesbox">
            <div className="imgdivvalues">
              <img src={icon2} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>U</b>nity
            </h5>
            <p className=" text-light font1">
              We achieve more with collaboration and team work
            </p>
          </div>
          <div className=" p-3 valuesbox">
            <div className="imgdivvalues">
              <img src={icon3} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>G</b>rowth
            </h5>
            <p className=" text-light font1">
              We constantly pursue <br /> business growth{" "}
            </p>
          </div>
          <div className=" p-3 valuesbox">
            <div className="imgdivvalues">
              <img src={icon4} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              {" "}
              <b>I</b>ntegrity
            </h5>
            <p className="text-light  font1">
              We carry out all the business activities with integrity and
              transparency
            </p>{" "}
          </div>
          <div className=" p-3 valuesbox">
            <div className="imgdivvalues">
              <img src={icon5} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>A</b>ccountability
            </h5>
            <p className=" text-light font1">
              Each of us is responsible for our words, actions and results.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5 py-5 valueformobile " id="#values">
        <div className="row">
          <h1 className="text-center text-primary  font2 display-5">
            Our Values
          </h1>
          <p className="text-center text-light  font1  ">
            We operate from a set of ineradicable core values that
            <br /> reflects every aspect of Eugia
          </p>
        </div>{" "}
        <div className="row text-center  my-2 mt-3">
          <div className=" p-3 col-md-12 ">
            <div className="imgdivvalues">
              <img src={icon1} alt="" width="40%" className="img-fluid" />
            </div>
            <h5 className="fs-1 my-4 font2 text-primary">
              <b> E</b>quality
            </h5>
            <p className="text-light  font1">
              We value everyone and treat people with respect, equality and
              digmity.{" "}
            </p>{" "}
          </div>
          <div className=" p-3 col-md-12 ">
            <div className="imgdivvalues">
              <img src={icon2} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>U</b>nity
            </h5>
            <p className=" text-light font1">
              We achieve more with collaboration and team work
            </p>
          </div>
          <div className=" p-3 col-md-12 ">
            <div className="imgdivvalues">
              <img src={icon3} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>G</b>rowth
            </h5>
            <p className=" text-light font1">
              We constantly pursue <br /> business growth{" "}
            </p>
          </div>
          <div className=" p-3 col-md-12 ">
            <div className="imgdivvalues">
              <img src={icon4} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              {" "}
              <b>I</b>ntegrity
            </h5>
            <p className="text-light  font1">
              We carry out all the business activities with integrity and
              transparency
            </p>{" "}
          </div>
          <div className=" p-3 col-md-12 ">
            <div className="imgdivvalues">
              <img src={icon5} alt="" width="40%" className="img-fluid" />
            </div>{" "}
            <h5 className="fs-1 my-4 font2 text-primary">
              <b>A</b>ccountability
            </h5>
            <p className=" text-light font1">
              Each of us is responsible for our words, actions and results.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row my-3">
          <h2 className="text-center text-primary my-2 font2 fs-1">
            Board of Directors
          </h2>
        </div>{" "}
        <Slider {...settings}>
          {dataBoard.map((ele) => (
            <>
              <div className="col-md-12 px-1 my-2">
                <Card className="p-0 border-0 ">
                  <div className="hover_color">
                    <div className="card-div cursor ">
                      <Card.Img
                        variant="top"
                        src={ele?.image}
                        className="img-div bod-img"
                      />
                    </div>

                    <Card.Body className="hover_color-inner cursor p-0">
                      <div>
                        <div className="hover_title">
                          <h5 className="  m-auto fs-2 font2">{ele?.title}</h5>
                          <p className=" my-3 font1">{ele?.description}</p>
                        </div>
                      </div>
                    </Card.Body>
                  </div>

                  <Card.Body className=" cursor p-0">
                    <p className=" text-primary m-auto fs-2 font2">
                      {ele?.title}
                    </p>
                    <p className="  font1 ">{ele?.designation}</p>
                  </Card.Body>
                </Card>
              </div>
            </>
          ))}
        </Slider>
      </div>

      {/* <div className="container " id="#director">
        <div className="row">
          <h1 className="text-center text-primary  font2 display-5">
            Board of Directors
          </h1>
        </div>{" "}
        <Slider {...settings}>
          {dataBoard.map((ele) => (
            <>
              <div className="col-md-12 px-1 my-2">
                <Card className="p-0 border-0 ">
                  <div className="hover_color">
                    <div className="card-div cursor ">
                      <Card.Img
                        variant="top"
                        src={ele?.image}
                        className="img-div"
                      />
                    </div>

                    <Card.Body className="hover_color-inner cursor p-0">
                      <div>
                        <div className="hover_title">
                          <h5 className="  m-auto fs-2 font2">{ele?.title}</h5>
                          <p className=" my-3 font1">{ele?.description}</p>
                        </div>
                      </div>
                    </Card.Body>
                  </div>

                  <Card.Body className=" cursor p-0">
                    <p className=" text-primary m-auto fs-2 font2">
                      {ele?.title}
                    </p>
                    <p className="  font1 ">{ele?.description.slice(0, 10)}</p>
                  </Card.Body>
                </Card>
              </div>

              <Card className="h-100 about-card">
                  <Card.Img variant="top" src={ele?.image} />
                  <Card.Body>
                    <Card.Title className="font2 fs-2 text-primary">{ele?.title}</Card.Title>
                    <p className=" font2 text-light">{ele?.designation}</p>
                    <Card.Text className="text-light">{ele?.description}</Card.Text>
                  </Card.Body>
                </Card>

              <div className="row equal my-4 py-5 mt-2 pt-0">
                <div className="col-md-10 d-flex pb-3 about-card px-1">
                  <div className="card card-block cardaboutus">
                    <Card.Img
                      variant="top"
                      src={ele?.image}
                      className="about-img img-fluid"
                    />
                    <Card.Body>
                      <Card.Title className="font2 fs-3 text-primary">
                        {ele?.title}
                      </Card.Title>
                      <p className=" font2 text-light about-text-p">
                        <b> {ele?.designation}</b>
                      </p>
                      <Card.Text className="text-light about-text-p">
                        {ele?.description}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </div>
              </div>
              <div className="row gy-4">
                  <div className="col-sm">
                    <div className="card h-100">
                      <img
                        src={ele?.image}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title fw-bold">{ele?.title}</h5>
                        <h6 className="card-title fw-bold">
                          {ele?.designation}
                        </h6>
                        <p className="card-text">{ele?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
            </>
          ))}
        </Slider>
      </div> */}

      <div className="container" style={{marginTop: "94px"}}>
        <div className="row">
          <h1 className="text-center text-primary  font2 display-5">
            Management Team
          </h1>
        </div>{" "}
        <Slider {...settings} className="">
          {data.map((ele) => (
            <>
              <div className="row equal my-4 py-5 mt-2 pt-0">
                <div className="col-md-10 d-flex  about-card px-1">
                  <div className="card card-block cardaboutus">
                    <Card.Img
                      variant="top"
                      src={ele?.image}
                      className="about-img img-fluid"
                    />
                    <Card.Body className="cardbody">
                      <Card.Title className="font2 fs-3 text-primary">
                        {ele?.name}
                      </Card.Title>
                      <p className=" font2 text-light about-text-p">
                        <b>{ele?.designation}</b>
                      </p>
                      <Card.Text className="text-light">
                        {ele?.description}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </div>
              </div>
            </>
          ))}
        </Slider>
      </div>

      {/* <div
        className="container my-5"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="board-scroll">
          <div className="row text-center" style={{ overflowY: "100px" }}>
            {dataBoard.map((ele) => (
              <>
                <div className="col-md-4 my-2">
                  <Card className="p-0 border-0 ">
                    <div className="hover_color">
                      <div className="card-div cursor ">
                        <Card.Img
                          variant="top"
                          src={ele?.image}
                          className="img-div"
                        />
                      </div>

                      <Card.Body className="hover_color-inner cursor p-0">
                        <div>
                          <div className="hover_title">
                            <h5 className="  m-auto fs-2 font2">
                              {ele?.title}
                            </h5>
                            <p className=" my-3 font1">{ele?.description}</p>
                          </div>
                        </div>
                      </Card.Body>
                    </div>

                    <Card.Body className=" cursor p-0">
                      <p className=" text-primary m-auto fs-2 font2">
                        {ele?.title}
                      </p>
                      <p className="  font1 ">
                        {ele?.description.slice(0, 10)}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="row">
          <h2 className="text-center text-primary my-5 font2 fs-1">
            Management Team
          </h2>
        </div>
        <div className="management-scroll">
          <div className="row text-center">
            {data.map((ele) => (
              <>
                <div className="col-md-4 my-2">
                  <Card className="p-0 border-0 ">
                    <div className="hover_color">
                      <div className="card-div cursor ">
                        <Card.Img
                          variant="top"
                          src={ele?.image}
                          className="img-div"
                        />
                      </div>

                      <Card.Body className="hover_color-inner cursor p-0">
                        <div>
                          <div className="hover_title">
                            <h5 className="  m-auto fs-2 font2">{ele?.name}</h5>
                            <p className=" my-3 font1">{ele?.designation}</p>
                          </div>
                        </div>
                      </Card.Body>
                    </div>

                    <Card.Body className=" cursor p-0">
                      <p className=" text-primary m-auto fs-2 font2">
                        {ele?.name}
                      </p>
                      <p className="  font1 ">
                        {ele?.designation.slice(0, 10)}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </>
            ))}
            {console.log(data, "datadatadata")}
          </div>
        </div>
      </div> */}

      {/* <div className="container" style={{ marginBottom: "10%" }}> */}
      {/* <div className="row">
          <div className="col-md-12">
            <h2 className="text-center text-primary my-5 font2 fs-1">
              State of Art Manufacturing Facilities
            </h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="text-center">
            <div className="row text-center">
              <div className="col-md-4">
                <img src={Eugia1} width="400px" height="400px" />
              </div>
              <div className="col-md-4">
                <img src={Eugia2} width="400px" height="400px" />
              </div>
              <div className="col-md-4">
                <img src={Eugia3} width="350px" height="350px" />
              </div>
            </div>
            <div className="row text-center" style={{ marginTop: "5%" }}>
              <div className="col-md-4">
                <img src={Eugia4} width="350px" height="350px" />
              </div>
              <div className="col-md-4">
                <img src={Eugia5} width="350px" height="350px" />
              </div>
              <div className="col-md-4">
                <a href="eugiaus.com">
                  <img src={Eugia6} width="350px" height="350px" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="row"></div> */}
      {/* </div> */}

      {/* <div
        className="Awards-img my-4 py-5"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <h3 className="text-center text-primary font2 fs-1">Awards</h3>
            </div>
          </div>
          <div className="row mt-5 ">
            {awardsList.slice(0, 3).map((ele) => (
              <>
                <div className="col-md-4  ">
                  <div className="mx-2 Awards-div">
                    <img src={ele?.image} alt="" className="w-100" />
                    <div className="p-3 m-auto">
                      <h5 className="font1">
                        <b>{ele?.description}</b>
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {console.log(awardsList, "awardsList===>")}
          </div>
        
        </div>
      </div> */}

      <div className="container ">
        <div className="row ">
          <h2 className="text-center text-primary font2 display-5">
            Our Global Presence
          </h2>
        </div>{" "}
        <div className="row my-4 py-5">
          <div className="col-md-12">
            <div class="container1">
              <img src={mapimg} alt="Snow" className="w-100 img-fluid" />
              <div class="bottom-left cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`} className=" text-white">
                      Latin America
                    </Tooltip>
                  }
                >
                  <img
                    src={redmaplocation} 
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>

              <div class="top-left cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Canada</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>
              <div class="top-left1 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>New Jersey, US</Tooltip>}
                >
                  <img
                    src={greenlocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>
              <div class="top-left2 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>US</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>
              <div class="top-right cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>Global Locations</Tooltip>
                  }
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>

              <div class="top-right11 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>Manufacturing Units</Tooltip>
                  }
                >
                  <img
                    src={greenlocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>

              <div class="bottom-right cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Australia</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100 img-fluid"
                  />
                </OverlayTrigger>
              </div>

              <div class="bottom-right1 cursor img-fluid">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Thailand</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100"
                  />
                </OverlayTrigger>
              </div>
              <div class="bottom-right2 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>South Africa</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100"
                  />
                </OverlayTrigger>
              </div>

              <div class="india1 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Telangana </Tooltip>}
                >
                  <img src={greenlocation} alt="Snow" className="w-100 h-100" />
                </OverlayTrigger>
              </div>
              <div class="india2 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>Bhiwadi - Rajasthan </Tooltip>
                  }
                >
                  <img src={greenlocation} alt="Snow" className="w-100 h-100" />
                </OverlayTrigger>
              </div>
              <div class="india3 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>Hyderabad - Telangana </Tooltip>
                  }
                >
                  <img src={greenlocation} alt="Snow" className="w-100 h-100" />
                </OverlayTrigger>
              </div>

              <div class="india4 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Vizag - Andhra Pradesh{" "}
                    </Tooltip>
                  }
                >
                  <img src={greenlocation} alt="Snow" className="w-100 h-100" />
                </OverlayTrigger>
              </div>

              <div class="centerred cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>UAE</Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100"
                  />
                </OverlayTrigger>
              </div>

              <div class="centerredtop cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Europe </Tooltip>}
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100"
                  />
                </OverlayTrigger>
              </div>

              <div class="centerredtop1 cursor">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>United Kingdom </Tooltip>
                  }
                >
                  <img
                    src={redmaplocation}
                    alt="Snow"
                    className="w-100 h-100"
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 py-5">
        <div className="my-2">
          <FooterSecond />
        </div>
      </div>

      <FooterFirst />
      <GoToTopButton />
    </>
  );
}

export default AboutPage;
