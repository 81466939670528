import React from "react";
import { useLocation } from "react-router-dom";
import FooterFirst from "../components/footer/FooterFirst";
import FooterSecond from "../components/footer/FooterSecond";
import GoToTopButton from "../components/footer/GoToTopButton";
import NavbarFirst from "../components/navs/Navbar";
import PageHistory from "../components/navs/PageHistory";
import treatmentshero from "./../assets/images/treatments-hero.png";

function Reports() {
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  return (
    <div>
      <NavbarFirst />

      <div className="herodiv">
        <img src={treatmentshero} alt="hero Img" width="100%" />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> Reports & Policies</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Reports & Policies" />
      <div className="container">
        <div className="report-container">
            <div className="row mt-5">
            <div className="col-md-12  text-primary">
                <h5> <b> Reports </b> </h5>
            </div>

                <div className="row">
                    <div className="report-main">
                        <div className="col-md-2 report">
                            <a href='http://eugia-api.moshimoshi.cloud/assets/1706875459555-Report of CSR.pdf' target="_blank" rel="noopener noreferrer">
                                <p className="report-name">CSR Report</p>  
                            </a>
                        </div>
                        <div className="col-md-2 report"> 
                            <a href='http://eugia-api.moshimoshi.cloud/assets/1706875406497-MOA and AOA of Eugia Pharma Specialities Ltd_05.09.2022.pdf' target="_blank" rel="noopener noreferrer">
                                <p className="report-name">MOA and AOA</p> 
                            </a>  
                        </div>
                        <div className="col-md-2 report"> 
                            <a href='http://eugia-api.moshimoshi.cloud/assets/1706875354162-Letter of Appointment_ ID Eugia.pdf' target="_blank" rel="noopener noreferrer">
                                <p className="report-name">Letter of Appointment</p>
                            </a>
                        </div>
                        <div className="col-md-2 report">
                            <a href='http://eugia-api.moshimoshi.cloud/assets/1706872808955-Form_MGT_7_Eugia Pharma_FY 2022-23 for website upload.pdf' target="_blank" rel="noopener noreferrer">
                                <p className="report-name">FY’23 Annual Return</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-12  text-primary">
                    <h5> <b>Policies</b> </h5>
                </div>
                <div className="row">
                        <div className="report-main">
                            <div className="col-md-2 report">
                                <a href='http://eugia-api.moshimoshi.cloud/assets/1706875517026-CSR-policy-Eugia.pdf' target="_blank" rel="noopener noreferrer">
                                    <p className="report-name">CSR Policy</p>   
                                </a>
                            </div>
                            <div className="col-md-2 report">
                                <a href='http://eugia-api.moshimoshi.cloud/assets/1706875577348-Nomination Remuneration Policy-EUGIA_Final_27.05.2022.pdf' target="_blank" rel="noopener noreferrer">
                                    <p className="report-name">Nomination & Remuneration Policy</p> 
                                </a> 
                            </div>
                            <div className="col-md-2 report">
                                <a href='http://eugia-api.moshimoshi.cloud/assets/1706875615305-Whistle Blower Policy-Eugia.pdf' target="_blank" rel="noopener noreferrer">
                                    <p className="report-name">Whistle Blower Policy</p>
                                </a>
                            </div>
                        </div>
                </div>
            </div>
        </div>
      </div>

      <FooterFirst />
      <GoToTopButton />
    </div>
  );
}

export default Reports;
