import React, { useEffect, useState } from "react";
import bottomtab from "./../../assets/images/bottomtab.png";
function GoToTopButton() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {showTopBtn && (
        <div className="top-to-btm">
          <span onClick={goToTop} className="icon-position  cursor">
            <img src={bottomtab} className="w-75" />
          </span>
        </div>
      )}
    </div>
  );
}

export default GoToTopButton;
